import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { AbstractTuiThemeSwitcher } from '@taiga-ui/cdk';
class TuiThemeNightComponent extends AbstractTuiThemeSwitcher {}
TuiThemeNightComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiThemeNightComponent_BaseFactory;
  return function TuiThemeNightComponent_Factory(t) {
    return (ɵTuiThemeNightComponent_BaseFactory || (ɵTuiThemeNightComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiThemeNightComponent)))(t || TuiThemeNightComponent);
  };
})();
TuiThemeNightComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiThemeNightComponent,
  selectors: [["tui-theme-night"]],
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function TuiThemeNightComponent_Template(rf, ctx) {},
  styles: [":root{--tui-base-01: #222;--tui-base-02: #333;--tui-base-03: #808080;--tui-base-04: #959595;--tui-base-05: #b0b0b0;--tui-base-06: #d7d7d7;--tui-base-07: #ededed;--tui-base-08: #f6f6f6;--tui-base-09: #fff;--tui-focus: rgba(51, 51, 51, .64);--tui-secondary: rgba(255, 255, 255, .16);--tui-secondary-hover: rgba(255, 255, 255, .24);--tui-secondary-active: rgba(255, 255, 255, .4);--tui-clear: rgba(255, 255, 255, .16);--tui-clear-disabled: rgba(255, 255, 255, .08);--tui-clear-hover: rgba(255, 255, 255, .24);--tui-clear-active: rgba(255, 255, 255, .4);--tui-error-fill: #ff8c67;--tui-error-bg: rgba(244, 87, 37, .32);--tui-error-bg-hover: rgba(244, 87, 37, .4);--tui-success-fill: #4ac99b;--tui-success-bg: rgba(74, 201, 155, .32);--tui-success-bg-hover: rgba(74, 201, 155, .4);--tui-warning-fill: #ffc700;--tui-warning-bg: rgba(255, 199, 0, .32);--tui-warning-bg-hover: rgba(255, 199, 0, .4);--tui-info-fill: #70b6f6;--tui-info-bg: rgba(112, 182, 246, .32);--tui-info-bg-hover: rgba(112, 182, 246, .4);--tui-neutral-fill: #959ba4;--tui-neutral-bg: rgba(149, 155, 164, .32);--tui-neutral-bg-hover: rgba(149, 155, 164, .48);--tui-text-01: #ffffff;--tui-text-02: rgba(255, 255, 255, .72);--tui-text-03: rgba(255, 255, 255, .6);--tui-link: #6788ff;--tui-link-hover: #526ed3;--tui-positive: #44c596;--tui-positive-hover: #3aa981;--tui-negative: #ff8c67;--tui-negative-hover: #bb593a;--tui-autofill: var(--tui-autofill-night);--tui-elevation-01: #222;--tui-elevation-02: #222;--tui-shadow: 0 .25rem 1.5rem rgba(0, 0, 0, .12);--tui-shadow-hover: 0 .75rem 2.25rem rgba(0, 0, 0, .2);--tui-shadow-dropdown: 0 1.5rem 1rem rgba(0, 0, 0, .03), 0 .75rem .75rem rgba(0, 0, 0, .04), 0 .25rem .375rem rgba(0, 0, 0, .05);--tui-shadow-modal: 0 1.125rem 1.875rem rgba(0, 0, 0, .48);--tui-shadow-sidebar: .25rem 0 1.5rem rgba(0, 0, 0, .12);--tui-shadow-navigation: 0 .125rem 1rem rgba(0, 0, 0, .08);--tui-shadow-sheet: 0 -1rem 1.75rem rgba(0, 0, 0, .24)}tui-notification{--tui-base-01: #000}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiThemeNightComponent, [{
    type: Component,
    args: [{
      selector: 'tui-theme-night',
      template: '',
      styleUrls: ['./theme-night.style.less'],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class TuiThemeNightModule {}
TuiThemeNightModule.ɵfac = function TuiThemeNightModule_Factory(t) {
  return new (t || TuiThemeNightModule)();
};
TuiThemeNightModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiThemeNightModule
});
TuiThemeNightModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiThemeNightModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiThemeNightComponent],
      exports: [TuiThemeNightComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiThemeNightComponent, TuiThemeNightModule };

import { TUI_SANITIZER } from '@taiga-ui/legacy';
import { NgDompurifySanitizer } from '@taiga-ui/dompurify';
import { TuiRoot, TuiAlert, TuiDialog } from '@taiga-ui/core';
import { APP_INITIALIZER, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TuiRoot, TuiDialog, TuiAlert, KeycloakAngularModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [{ provide: TUI_SANITIZER, useClass: NgDompurifySanitizer }],
})
export class AppComponent {
  title = 'vermo-ui';
}

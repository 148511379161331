import * as i0 from '@angular/core';
import { InjectionToken, inject, SecurityContext, Injectable, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import dompurify from 'dompurify';

/**
 * Token for {@link NgDompurifyConfig}
 */
const DOMPURIFY_CONFIG = new InjectionToken('[DOMPURIFY_CONFIG]: Config for DOMPurify', {
  factory: () => ({}),
  providedIn: 'root'
});

/**
 * Token for adding hooks to DOMPurify, see {@link addHook}
 */
const DOMPURIFY_HOOKS = new InjectionToken('[DOMPURIFY_HOOKS]: Hooks for DOMPurify', {
  factory: () => [],
  providedIn: 'root'
});

/**
 * A function that takes style rule value as input and returns a sanitized string
 *
 * NOTE: Angular 10 removed CSS sanitation so by default this method does nothing
 */
const SANITIZE_STYLE = new InjectionToken('[SANITIZE_STYLE]: A function that sanitizes value for a CSS rule', {
  factory: () => value => value,
  providedIn: 'root'
});
const createDOMPurify = dompurify;
/**
 * Implementation of Angular {@link Sanitizer} purifying via DOMPurify
 *
 * use {@link DOMPURIFY_CONFIG} token to provide config ({@link NgDompurifyConfig})
 * use {@link SANITIZE_STYLE} token to provide a style sanitizing method ({@link SanitizeStyle})
 * use {@link DOMPURIFY_HOOKS} token to provide a hooks for DOMPurify ({@link addHook})
 *
 * Ambient type cannot be used without @dynamic https://github.com/angular/angular/issues/23395
 * @dynamic
 */
class NgDompurifySanitizer {
  constructor() {
    this.config = inject(DOMPURIFY_CONFIG);
    this.sanitizeStyle = inject(SANITIZE_STYLE);
    this.domPurify = createDOMPurify(inject(DOCUMENT).defaultView);
    inject(DOMPURIFY_HOOKS).forEach(({
      name,
      hook
    }) => {
      this.domPurify.addHook(name, hook);
    });
  }
  sanitize(context, value, config = this.config) {
    if (context === SecurityContext.SCRIPT) {
      throw new Error('DOMPurify does not support SCRIPT context');
    }
    return context === SecurityContext.STYLE ? this.sanitizeStyle(String(value)) : this.domPurify.sanitize(String(value || ''), config);
  }
  static {
    this.ɵfac = function NgDompurifySanitizer_Factory(t) {
      return new (t || NgDompurifySanitizer)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgDompurifySanitizer,
      factory: NgDompurifySanitizer.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgDompurifySanitizer, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/**
 * Pipe that transforms dirty content to clean via {@link NgDompurifySanitizer}
 */
class NgDompurifyPipe {
  constructor() {
    this.sanitizer = inject(NgDompurifySanitizer);
    this.domSanitizer = inject(DomSanitizer);
  }
  transform(value, context = SecurityContext.HTML, config) {
    return this.bypassSecurityTrust(context, this.sanitizer.sanitize(context, value, config));
  }
  bypassSecurityTrust(context, purifiedValue) {
    switch (context) {
      case SecurityContext.HTML:
        return this.domSanitizer.bypassSecurityTrustHtml(purifiedValue);
      case SecurityContext.RESOURCE_URL:
        return this.domSanitizer.bypassSecurityTrustResourceUrl(purifiedValue);
      case SecurityContext.STYLE:
        return this.domSanitizer.bypassSecurityTrustStyle(purifiedValue);
      case SecurityContext.URL:
        return this.domSanitizer.bypassSecurityTrustUrl(purifiedValue);
      default:
        return null;
    }
  }
  static {
    this.ɵfac = function NgDompurifyPipe_Factory(t) {
      return new (t || NgDompurifyPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "dompurify",
      type: NgDompurifyPipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgDompurifyPipe, [{
    type: Pipe,
    args: [{
      standalone: true,
      name: 'dompurify'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DOMPURIFY_CONFIG, DOMPURIFY_HOOKS, NgDompurifyPipe, NgDompurifySanitizer, SANITIZE_STYLE };
